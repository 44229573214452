import { Pipe, PipeTransform } from '@angular/core';
import { TimeUtils } from '@wndr/common/core/utils/time-utils';

/** Convert total seconds to MM:SS format. */
@Pipe({
	name: 'wndrcTime',
	standalone: true,
})
export class TimePipe implements PipeTransform {
	/** @inheritdoc */
	public transform(seconds: number | null): string {
		return TimeUtils.toMinutesAndSeconds(seconds ?? 0);
	}
}
